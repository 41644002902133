import Whatsapp from '@/Modules/Test/Whatsapp.vue';


const authRoutes = [
  {
    path: '/test/whatsapp',
    name: 'Whatsapp',
    component: Whatsapp
  },
];

export default authRoutes;
