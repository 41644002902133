<template>
    <div class="animate-pulse aside h-screen fixed w-[354px] bg-white right-0">
        <div class="px-[24px] py-[18px] border-b-[2px] border-[#BFBFBF] flex">
            <div class="w-[52px] h-[52px] item-skeletom"></div>
            <div class="ml-[16px]">
                <div class="item-skeletom h-[12px] w-[183px] mb-[10px]"></div>
                <div class="item-skeletom h-[12px] w-[100px]"></div>
            </div>
        </div>
        <div class="mt-[24px] w-full flex justify-center">
            <div class="px-[20px] py-[8px] w-[158px]">
                <div class="item-skeletom h-[12px] w-full"></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .aside {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    }
</style>