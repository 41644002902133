<template>
    <div class="bg-white rounded-[10px] mb-6">
        <div class="py-6 px-4 animate-pulse">
            <div class="flex justify-between items-center">
                <div class="flex items-center">
                    <div class="w-[24px] h-[24px] rounded-full bg-gray-300"></div>
                    <div class="ml-[8px] bg-gray-300 h-[12px] w-[180px] rounded"></div>
                </div>
                <div class="bg-gray-300 h-[18px] w-[180px] rounded-full"></div>
            </div>
            <div class="mt-[8px] bg-gray-300 h-[12px] w-[74px] rounded-full"></div>
            <div class="item-skeletom mt-[10px] h-[12px] w-[182px]"></div>
            <div class="flex items-center mt-[8px]">
                <div class="item-skeletom mt-[10px] h-[12px] w-[131px] mr-[8px]"></div>
                <div class="item-skeletom mt-[10px] h-[12px] w-[54px]"></div>
            </div>
            <div class="item-skeletom mt-[10px] h-[12px] w-[628px]"></div>
            <div class="item-skeletom mt-[10px] h-[12px] w-[439px]"></div>
            <div class="item-skeletom mt-[10px] h-[12px] w-[516px]"></div>
            <div class="item-skeletom mt-[10px] h-[12px] w-[615px]"></div>
            <div class="item-skeletom mt-[37px] h-[12px] w-[67px]"></div>
            <div class="flex mt-[8px]">
                <div class="py-[8px] px-[12px] w-[105px]">
                    <div class="item-skeletom h-[12px] w-full"></div>
                </div>
                <div class="py-[8px] px-[12px] w-[92px] ml-4">
                    <div class="item-skeletom h-[12px] w-full"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white rounded-[10px] mb-6">
        <div class="py-6 px-4 animate-pulse">
            <div class="flex justify-between mt-[16px]">
                <div class="item-skeletom h-[12px] w-[105px]"></div>
                <div class="py-[4px] px-[8px]">
                    <div class="item-skeletom h-[12px] w-[92px]"></div>
                </div>
            </div>
            <div class="item-skeletom h-[12px] w-[625px]"></div>
            <div class="item-skeletom h-[12px] w-[413px] mt-[4px]"></div>
            <div class="mt-[28px] flex justify-end">
                <div class="p-[8px] flex">
                    <div class="item-skeletom h-[12px] w-[120px] mr-[16px]"></div>
                    <div class="item-skeletom h-[12px] w-[120px] mr-[24px]"></div>
                </div>
            </div>
        </div>
    </div>
</template>